var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zeds-card", style: { height: "160px" } }, [
    _c("div", { staticClass: "fw500 flex bet a-center" }, [
      _c("div", {
        style: { "margin-bottom": "10px", "margin-right": "25px" },
        domProps: {
          textContent: _vm._s(
            _vm.$t("Zed number") + " " + _vm.item.branch_zed_id
          ),
        },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "zeds-card__main",
        style: {
          display: "flex",
          "justify-content": "space-between",
          width: "100%",
        },
        on: { click: _vm.data.click },
      },
      [
        _c("div", { staticClass: "item-card__main_name" }, [
          _c("div", {
            staticClass: "grey",
            domProps: { textContent: _vm._s(_vm.$t("Closed by")) },
          }),
          _c("div", { domProps: { textContent: _vm._s(_vm.item.closed_by) } }),
          _c("div", {
            staticClass: "grey",
            style: { "margin-top": "10px" },
            domProps: { textContent: _vm._s(_vm.$t("Date")) },
          }),
          _c("div", {
            domProps: {
              textContent: _vm._s(_vm.filters.clockTimeDate(_vm.item.closed)),
            },
          }),
          _c(
            "div",
            {
              style: {
                display: "flex",
                "justify-content": "space-between",
                width: "100%",
              },
            },
            [
              _c("div", {
                staticClass: "grey",
                style: { "margin-top": "10px" },
                domProps: { textContent: _vm._s(_vm.$t("Value date")) },
              }),
              _c("div", {
                style: { "margin-top": "10px", "margin-right": "7px" },
                domProps: {
                  textContent: _vm._s(_vm.filters.date(_vm.item.value_date)),
                },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "item-card__main_name" }, [
          _c("div", {
            staticClass: "grey",
            domProps: { textContent: _vm._s(_vm.$t("Gross")) },
          }),
          _vm.restaurantCurrencyInfo == null
            ? _c("div", {
                domProps: {
                  textContent: _vm._s(
                    "₪" + _vm.item.orders_total_sum.toFixed(2)
                  ),
                },
              })
            : _vm._e(),
          _vm.restaurantCurrencyInfo != null
            ? _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.restaurantCurrencySymbol +
                      _vm.item.orders_total_sum.toFixed(2)
                  ),
                },
              })
            : _vm._e(),
          _c("div", {
            staticClass: "grey",
            style: { "margin-top": "10px" },
            domProps: { textContent: _vm._s(_vm.$t("Net")) },
          }),
          _vm.restaurantCurrencyInfo == null
            ? _c("div", {
                domProps: {
                  textContent: _vm._s("₪" + _vm.item.net.toFixed(2)),
                },
              })
            : _vm._e(),
          _vm.restaurantCurrencyInfo !== null
            ? _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.restaurantCurrencySymbol + _vm.item.net.toFixed(2)
                  ),
                },
              })
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }